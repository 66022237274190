import { IconCheckMarkSolid, Select, Tag, View } from '@instructure/ui'
import {
  KeyboardEvent,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import type { Renderable } from '@instructure/shared-types'
import { callRenderProp } from '@instructure/ui-react-utils'

import type { SelectProps, SelectOptionProps } from '@instructure/ui-select'
import type { VirtualItem } from '@tanstack/react-virtual'
import type { FilterConfig } from './filter'
import {
  FilterOptionOption,
  GetOptionLabel,
  GetOptionValue,
  GroupBase,
  InputActionMeta,
  NormalizedOption,
  OnChangeValue,
  Options,
  PropsValue,
} from './types'
import {
  formatGroupLabel as defaultFormatGroupLabel,
  isOptionDisabled as defaultIsOptionDisabled,
  getOptionLabel as defaultGetOptionLabel,
  getOptionValue as defaultGetOptionValue,
} from './defaultMethods'

import { createFilter } from './filter'
import { useOptionUtils } from './hooks/useOptionUtils'
import {
  useNormalizedOptions,
  isGroupGuard,
} from './hooks/useNormalizedOptions'
import { DEFAULT_OPTION_HEIGHT_PX } from './const'
import { useVirtualization } from './hooks/useVirtualization'
import { multiValueAsValue, singleValueAsValue } from './utils'
import { flushSync } from 'react-dom'

export type FormatOptionLabelContext = 'list' | 'selected-value'

export type FormatOptionLabelMeta<Option> = {
  context: FormatOptionLabelContext
  inputValue: string
  selectValue: Options<Option>
  isHighlighted?: boolean
}

export type OptionsOrGroups<
  Option,
  Group extends GroupBase<Option>
> = readonly (Option | Group)[]

export type Props<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
> = {
  options: OptionsOrGroups<Option, Group>
  multiSelect?: IsMulti
  placeholder?: string
  optionsMaxHeight?: string
  visibleOptionsCount?: number
  /** Override the default logic to detect whether an option is selected */
  isOptionDisabled?: <Option>(
    option: Option,
    selectValue: Options<Option>
  ) => boolean
  /** Override the default logic to detect whether an option is selected */
  isOptionSelected?: (option: Option, selectValue: Options<Option>) => boolean
  filterOption?:
    | ((option: FilterOptionOption<Option>, inputValue: string) => boolean)
    | null
  formatGroupLabel?: (group: Group) => ReactNode

  /**
   * Resolves option data to a string to be displayed as the option label
   */
  getOptionLabel?: GetOptionLabel<Option>
  /** Resolves option data to a string to compare options and specify value attributes */
  getOptionValue?: GetOptionValue<Option>
  /** Formats option labels in the list or in the input */
  formatOptionLabel?: (
    data: Option,
    formatOptionLabelMeta: FormatOptionLabelMeta<Option>
  ) => ReactNode

  /**
   * Controls what should be rendered before the option's label(on the left)
   * By default, a checkmark is rendered if the option is selected. And empty space is rendered if not selected.
   * A custom render function can be provided to render something else
   * if null is returned, the default checkmark or empty space will be rendered
   **/
  renderBeforeOptionLabel?: (
    option: Option,
    isHighlighted?: boolean
  ) => SelectOptionProps['renderBeforeLabel']

  // state props
  inputValue?: string
  defaultInputValue?: string
  defaultValue?: PropsValue<Option>
  onChange?: (value: OnChangeValue<Option, IsMulti>) => void
  onBlur?: () => void
  onInputChange?: (
    newValue: string,
    actionMeta: InputActionMeta<Option>
  ) => string | void
  value?: PropsValue<Option>
  /** Whether or not to close the Select when an option is selected */
  closeAfterSelect?: boolean
} & PassedToSelectProps

type PassedToSelectProps = {
  renderLabel: Renderable
  interaction?: SelectProps['interaction']
  width?: SelectProps['width']
  /**
   * A ref to the html `input` element.
   */
  inputRef?: (inputElement: HTMLInputElement | null) => void
}


/**
 * This is a wrapper around the UI Select component that adds some extra features:
 * - Virtualization
 * - Custom Filtering logic
 * - Custom formatting
 * - Custom rendering
 * - Custom option selection logic
 * - Custom option highlighting logic
 * - Custom option disabled logic
 *
 * @TODO: Cover with additional UI and unit tests
 *        https://instructure.atlassian.net/browse/MCE-20478
 * @TODO: Refactor: extract the state logic into a custom hook, extract the BaseSelect as a separate component,
 *    this way there will be a foundation for extending the EasySelect component to AsyncEasySelect,
 *    InfiniteScrollEasySelect, CreatableEasySelect etc.
 *    https://instructure.atlassian.net/browse/MCE-20479
 * @TODO: Add Screen reader support when navigating with the keyboard
 *      https://instructure.atlassian.net/browse/MCE-20480
 * @TODO: Add support for `isSearchable` prop -  so that the component can be used as a simple dropdown
 *      https://instructure.atlassian.net/browse/MCE-20481
 */
const EasySelect = <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  options,
  renderLabel,
  width,
  placeholder,
  optionsMaxHeight,
  visibleOptionsCount = 8,
  isOptionSelected = null,
  isOptionDisabled = defaultIsOptionDisabled,
  filterOption = createFilter(),
  formatGroupLabel = defaultFormatGroupLabel,
  formatOptionLabel: propsFormatOptionsLabel = null,
  getOptionLabel = defaultGetOptionLabel,
  getOptionValue = defaultGetOptionValue,
  multiSelect,
  closeAfterSelect = true,
  onBlur: propsOnBlur,
  // state props
  inputValue: propsInputValue,
  defaultInputValue = '',
  onChange: propsOnChange,
  onInputChange: propsOnInputChange,
  value: propsValue,
  defaultValue = null,
  renderBeforeOptionLabel: propsRenderBeforeOptionLabel,
  interaction,
  inputRef: propsInputRef,
  ...restAttrs
}: Props<Option, IsMulti, Group>) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const needToScrollToSelectedOption = useRef(false)

  const [isShowingOptions, setIsShowingOptions] = useState(false)
  const [optionsHiddenAfterUpdate, setOptionsHiddenAfterUpdate] =
    useState(false)
  const [highlightedOptionId, setHighlightedOptionId] = useState(null)
  const [stateInputValue, setStateInputValue] = useState(
    propsInputValue !== undefined ? propsInputValue : defaultInputValue
  )

  const [stateValue, setStateValue] = useState(
    propsValue !== undefined ? propsValue : defaultValue
  )

  const selectValue = prepareValue(
    propsValue !== undefined ? propsValue : stateValue
  )
  const hasSelectedValue = selectValue.length > 0
  const inputValue =
    propsInputValue !== undefined ? propsInputValue : stateInputValue

  /*
   * Focus the input when the options are shown
   * This is needed because different Select components are rendered depending on whether the options are shown or not
   */
  useEffect(() => {
    if (isShowingOptions) {
      inputRef.current?.focus()
    }
  }, [isShowingOptions])

  /*
   * Focus the input when the options are hidden after an update
   * Otherwise the input will lose focus when the options are hidden, because the different instance of the Select component is rendered
   */
  useEffect(() => {
    if (optionsHiddenAfterUpdate) {
      setOptionsHiddenAfterUpdate(false)
      inputRef.current?.focus()
    }
  }, [optionsHiddenAfterUpdate])

  const handleInputRef = (node: HTMLInputElement) => {
    inputRef.current = node
    propsInputRef?.(node)
  }

  const optionUtils = useOptionUtils({
    isOptionDisabled,
    isOptionSelected,
    getOptionValue,
    getOptionLabel,
    filterOption,
  })

  const { buildNormalizedOptions, getFirstSelectedOptionIndex } =
    useNormalizedOptions<Option, IsMulti, Group>(optionUtils)

  const normalizedOptions = isShowingOptions
    ? buildNormalizedOptions(options, selectValue, inputValue)
    : []

  const handleOnChange = useCallback(
    (value: OnChangeValue<Option, IsMulti>) => {
      if (typeof propsOnChange === 'function') {
        propsOnChange(value)
      }
      setStateValue(value)
    },
    [propsOnChange]
  )

  const handleShowOptions = () => {
    flushSync(() => {
      setIsShowingOptions(true)
    })

    needToScrollToSelectedOption.current = true
  }

  const handleHighlightOption = (event: SyntheticEvent, { id }) => {
    // Ignore the highlight request from the Select component when navigating with a keyboard
    if (
      isShowingOptions &&
      event.type === 'keydown' &&
      ['ArrowUp', 'ArrowDown', 'Home', 'End'].includes(
        (event as unknown as KeyboardEvent).key
      )
    ) {
      return
    }

    if (id === highlightedOptionId) return

    setHighlightedOptionId(id)
  }

  const handleBlur = () => {
    if (typeof propsOnBlur === 'function') {
      propsOnBlur()
    }
  }

  /**
   *
   * Handles keyboard navigation
   * Overrides some of the Select component's default behavior for keyboard navigation
   * This is needed because the Select component does not support virtualization
   * Also, Select component has bugs and inconsistencies with keyboard navigation
   */
  const handleKeyboardNavigation = (event: KeyboardEvent) => {
    if (!isShowingOptions) return
    const highlightedIndex = getNormalizedOptionById(highlightedOptionId)?.index

    let newHighlightedId = null

    switch (event.key) {
      case 'ArrowDown': {
        if (highlightedOptionId === null) {
          // if there is no highlighted option, highlight the first option
          newHighlightedId = getFirstOptionId()

          break
        }
        const lastOptionIsHighlighted =
          highlightedIndex === normalizedOptions.length - 1
        if (lastOptionIsHighlighted) {
          // if the last option is highlighted and the user presses ArrowDown, highlight the first option
          newHighlightedId = getFirstOptionId()
        } else {
          // otherwise, highlight the next option. The next option should be of type === option
          const nextOption = normalizedOptions.find(
            (item, index) => index > highlightedIndex && item.type === 'option'
          ) as NormalizedOption<Option>

          newHighlightedId = nextOption
            ? getNormalizedOptionId(nextOption)
            : null
        }

        break
      }

      case 'ArrowUp': {
        const firstOptionIsHighlighted =
          highlightedOptionId === getFirstOptionId()

        if (firstOptionIsHighlighted) {
          // if the first option is highlighted and the user presses ArrowUp, highlight the last option
          newHighlightedId = getLastOptionId()
        } else {
          // otherwise, highlight the previous option. The previous option should be of type === option
          let prevOptionId = null
          for (let i = highlightedIndex - 1; i >= 0; i--) {
            const item = normalizedOptions[i]
            if (item.type === 'option') {
              prevOptionId = getNormalizedOptionId(item)
              break
            }
          }
          newHighlightedId = prevOptionId
        }
        break
      }

      case 'Home':
        newHighlightedId = getFirstOptionId()
        break

      case 'End':
        newHighlightedId = getLastOptionId()
        break
    }
    if (newHighlightedId === null) return

    setHighlightedOptionId(newHighlightedId)
    virtualizer.scrollToIndex(getNormalizedOptionById(newHighlightedId).index)
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (!inputValue && inputRef.current) {
      inputRef.current.value = ''
    }

    handleKeyboardNavigation(event)
  }

  const getNormalizedOptionById = (id: string) => {
    return normalizedOptions[id]
  }
  const handleSelectOption = (_, { id }) => {
    selectOption(getNormalizedOptionById(id).data as Option)
  }

  const handleHideOptions = () => {
    setIsShowingOptions(false)
    setHighlightedOptionId(null)
    changeInputValue('', { action: 'hide-options', prevInputValue: inputValue })
  }

  const changeInputValue = (
    value: string,
    actionMeta: InputActionMeta<Option>
  ) => {
    let newValue: string | undefined
    if (typeof propsOnInputChange === 'function') {
      const result = propsOnInputChange(value, actionMeta)
      if (typeof result === 'string') {
        newValue = result
      }
    }
    setStateInputValue(newValue !== undefined ? newValue : value)
  }

  const handleInputChange = (event) => {
    changeInputValue(event.target.value, {
      action: 'input-change',
      prevInputValue: inputValue,
    })
  }

  const getNormalizedOptionId = (option: NormalizedOption<Option>) => {
    return String(option.index)
  }

  const renderedItemsVirtualIndicies = []
  const renderOption = (
    virtualRow: VirtualItem<Element>,
    virtualItems: VirtualItem<Element>[]
  ): ReactNode => {
    if (renderedItemsVirtualIndicies.includes(virtualRow.index)) {
      return null
    }

    renderedItemsVirtualIndicies.push(virtualRow.index)

    const item = normalizedOptions[virtualRow.index]

    if (item.type === 'group') {
      return (
        <Select.Group
          renderLabel={formatGroupLabel(item.data)}
          key={item.index}
          data-index={virtualRow.index}
        >
          {item.options
            .map((_, index) => {
              const virtualIndex = virtualRow.index + index + 1
              const indexIsInVirtualItems = virtualItems.findIndex(
                (item) => item.index === virtualIndex
              )

              if (indexIsInVirtualItems === -1) {
                return null
              }

              return renderOption(
                virtualItems[indexIsInVirtualItems],
                virtualItems
              )
            })
            .filter(Boolean)}
        </Select.Group>
      )
    }

    const { index, isDisabled, isSelected, data } = item

    const isHighlighted = getNormalizedOptionId(item) === highlightedOptionId

    const customRenderBeforeOptionLabel = propsRenderBeforeOptionLabel?.(
      data,
      isHighlighted
    )

    const renderBeforeLabel = isSelected ? (
      <IconCheckMarkSolid size="x-small" />
    ) : customRenderBeforeOptionLabel ? (
      customRenderBeforeOptionLabel
    ) : (
      <View as="div" margin={'0 medium 0 0'} />
    )

    return (
      <Select.Option
        id={getNormalizedOptionId(item)}
        key={index}
        isHighlighted={isHighlighted}
        isDisabled={isDisabled}
        renderBeforeLabel={renderBeforeLabel}
        data-index={virtualRow.index}
      >
        {formatOptionLabel(data, 'list', isHighlighted)}
      </Select.Option>
    )
  }

  const setValue = (
    newValue: OnChangeValue<Option, IsMulti>,
    option?: Option
  ) => {
    changeInputValue('', {
      action: 'set-value',
      prevInputValue: inputValue,
      option,
    })
    if (closeAfterSelect) {
      setIsShowingOptions(false)
      setOptionsHiddenAfterUpdate(true)
    }
    handleOnChange(newValue)
  }

  const selectOption = (newValue: Option) => {
    const deselected =
      multiSelect && optionUtils.isOptionSelected(newValue, selectValue)

    if (deselected) {
      const valueToRemove = getOptionValue(newValue)
      setValue(
        multiValueAsValue(
          selectValue.filter((i) => getOptionValue(i) !== valueToRemove)
        )
      )
    } else {
      if (multiSelect) {
        setValue(multiValueAsValue([...selectValue, newValue]), newValue)
      } else {
        setValue(singleValueAsValue(newValue), newValue)
      }
    }
  }
  const dismissTag = (e, removedOption: Option) => {
    // prevent closing of list
    e.stopPropagation()
    e.preventDefault()

    const removedValue = getOptionValue(removedOption)
    const newSelectValue = selectValue.filter(
      (i) => getOptionValue(i) !== removedValue
    )
    handleOnChange(multiValueAsValue(newSelectValue))
  }

  const renderSelectedValuesBeforeInput = () => {
    if (!multiSelect) return null
    return selectValue.map((selectedOption, index) => {
      const optionLabel = callRenderProp(
        formatOptionLabel(selectedOption, 'selected-value')
      )
      return (
        optionLabel !== null &&
        optionLabel !== undefined && (
          <Tag
            dismissible
            key={`${getOptionLabel(selectedOption)}-${index}`}
            title={`Remove ${optionLabel}`}
            text={callRenderProp(optionLabel)}
            margin={
              index > 0 ? 'xxx-small 0 xxx-small xx-small' : 'xxx-small 0'
            }
            onClick={(e) => dismissTag(e, selectedOption)}
          />
        )
      )
    })
  }

  const { virtualizer, handleListRef, hasRenderedOptions } = useVirtualization(
    normalizedOptions.length,
    (index) => `${normalizedOptions[index]?.index}`
  )

  scrollToTheFirstIndexIfNeeded()

  const virtualItems = virtualizer.getVirtualItems()

  const renderInputValueOrSelectedValue = (): string | null => {
    if (multiSelect || inputValue) {
      return inputValue
    }

    return hasSelectedValue
      ? (formatOptionLabel(selectValue[0], 'selected-value') as string)
      : ''
  }

  return (
    <>
      {!isShowingOptions && (
        <Select
          inputRef={handleInputRef}
          key={'hidden'}
          width={width}
          renderLabel={renderLabel}
          placeholder={placeholder}
          assistiveText="Use arrow keys to navigate options."
          inputValue={renderInputValueOrSelectedValue()}
          isShowingOptions={isShowingOptions}
          onBlur={handleBlur}
          onRequestShowOptions={handleShowOptions}
          renderBeforeInput={renderSelectedValuesBeforeInput}
          interaction={interaction}
          {...restAttrs}
        ></Select>
      )}
      {isShowingOptions && (
        <Select
          scrollToHighlightedOption={false}
          inputRef={handleInputRef}
          key={'showing'}
          placeholder={placeholder}
          width={width}
          renderLabel={renderLabel}
          assistiveText="Use arrow keys to navigate options."
          inputValue={renderInputValueOrSelectedValue()}
          isShowingOptions={isShowingOptions}
          onBlur={handleBlur}
          onRequestShowOptions={handleShowOptions}
          onRequestHideOptions={handleHideOptions}
          onRequestHighlightOption={handleHighlightOption}
          onRequestSelectOption={handleSelectOption}
          onInputChange={handleInputChange}
          onKeyDown={handleKeyDown}
          optionsMaxHeight={`${
            optionsMaxHeight || visibleOptionsCount * DEFAULT_OPTION_HEIGHT_PX
          }px`}
          renderBeforeInput={renderSelectedValuesBeforeInput}
          listRef={handleListRef}
          interaction={interaction}
          {...restAttrs}
        >
          {virtualItems
            .map((virtualRow) => renderOption(virtualRow, virtualItems))
            .filter(Boolean)}
        </Select>
      )}
    </>
  )

  function formatOptionLabel(
    data: Option,
    context: FormatOptionLabelContext,
    isHighlighted?: boolean
  ): ReactNode {
    if (typeof propsFormatOptionsLabel === 'function') {
      return propsFormatOptionsLabel(data, {
        context,
        inputValue,
        selectValue,
        isHighlighted,
      })
    } else {
      return getOptionLabel(data)
    }
  }

  function prepareValue<Option>(value: PropsValue<Option>): Options<Option> {
    if (Array.isArray(value)) return value.filter(Boolean)
    if (typeof value === 'object' && value !== null)
      return [value] as Options<Option>
    return []
  }

  function getFirstOptionId() {
    const firstOption = normalizedOptions.find(
      (item) => item.type === 'option'
    ) as NormalizedOption<Option>

    return firstOption ? getNormalizedOptionId(firstOption) : null
  }

  function getLastOptionId() {
    for (let i = normalizedOptions.length - 1; i >= 0; i--) {
      const option = normalizedOptions[i]
      if (option.type === 'option') {
        return getNormalizedOptionId(option)
      }
    }
    return null
  }

  /*
   * Scroll to the first selected option if there is a selected option and the options are shown
   */
  function scrollToTheFirstIndexIfNeeded() {
    if (
      needToScrollToSelectedOption.current &&
      isShowingOptions &&
      hasSelectedValue &&
      hasRenderedOptions()
    ) {
      needToScrollToSelectedOption.current = false
      const firstSelectedOptionIndex = getFirstSelectedOptionIndex()
      requestAnimationFrame(() =>
        virtualizer.scrollToIndex(firstSelectedOptionIndex)
      )
    }
  }
}
export {
  EasySelect,
  createFilter,
  defaultGetOptionValue,
  defaultGetOptionLabel,
  defaultIsOptionDisabled,
  defaultFormatGroupLabel,
  isGroupGuard,
}
export type {
  FilterOptionOption,
  GetOptionLabel,
  GetOptionValue,
  GroupBase,
  InputActionMeta,
  FilterConfig,
}
